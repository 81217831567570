/**
 * 基本的には development, production, test で分けたい
 * pr-staging のような QA 環境については、production と同じ設定を使う
 * そのため、接続先などは環境変数を使って制御する
 *
 *
 */
import { loadDevelopmentEnvironment } from "~/lib/environments/development";
import { loadProductionEnvironment } from "~/lib/environments/production";

export interface Environment {
  backendHost: string;
  microCmsEndpoint?: string; // backend で使う場合のみ
  microCmsApiKey?: string; // backend で使う場合のみ
}

function environment(): Environment {
  const nodeEnv = process.env.NODE_ENV;

  let env;

  switch (nodeEnv) {
    case "development":
    case "test":
      env = loadDevelopmentEnvironment();
      break;
    case "production":
      env = loadProductionEnvironment();
      break;
  }

  return env;
}

export const cdnFileUrl = (path: string) =>
  `https://d1stpor4o81wuq.cloudfront.net${path}`;

export default environment;
