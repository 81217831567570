import dayjs from "dayjs";
import { logEvent as firebaseLogEvent, getAnalytics } from "firebase/analytics";
import type { FirebaseApp } from "firebase/app";
import { getApps, initializeApp } from "firebase/app";
import { Auth } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA3erDaPO4BXgBGALvvxl2DfFcDDB0U0VY",
  authDomain: "fussy-web.firebaseapp.com",
  projectId: "fussy-web",
  storageBucket: "fussy-web.appspot.com",
  messagingSenderId: "843091091157",
  appId: "1:843091091157:web:21ef39e5adc9af2c368c5c",
  measurementId: "G-DVGYL5C17V",
};

export const getFirebaseApp = (): FirebaseApp | undefined => {
  if (typeof window === "undefined") return;

  return getApps()[0] || initializeApp(firebaseConfig);
};

export const getFirebaseAuth = (): Auth => {
  return getAuth(getFirebaseApp());
};

const getFirebaseAnalytics = () => {
  return getAnalytics(getFirebaseApp());
};

export const logEvent = (eventName: string) =>
  firebaseLogEvent(getFirebaseAnalytics(), eventName);

export function useFirebaseAuth() {
  const hook = useAuthState(getFirebaseAuth());

  useEffect(() => {
    if (!hook[0]) return;

    const firebaseUser = hook[0];

    const expiresIn =
      dayjs((firebaseUser as any).stsTokenManager.expirationTime).diff(
        dayjs(),
        "second"
      ) - 60; // トークンが切れる 60 秒前に更新する

    const timer = setInterval(
      () => firebaseUser.getIdToken(true).catch(console.error),
      expiresIn * 1000
    );

    return () => clearTimeout(timer);
  }, [hook]);

  return hook;
}

/**
 * FIXME: これを直接 _layout で呼ぶと next build でエラーが出るので、ここで呼ぶ。それ以外には使わないようにしてほしい。
 * しかもデッドコードのように思われるが消すと next build でエラーが出るので消せない。
 *
 * author: funwarioisii
 */
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
