import { GraphQLClient } from "graphql-request";

import environment from "~/lib/environment";
import ExhaustiveError from "~/lib/errors";
import {
  AnswerTypeEnum,
  AnswerUnion,
  Scalars,
  getSdk,
} from "~/lib/graphql/client";

export function createGraphqlClient(idToken: string | null = null) {
  const host = environment().backendHost;
  const url = `${host}/graphql`;
  const client = new GraphQLClient(url);
  client.setHeader("Content-Type", "application/json");
  client.setHeader("X-Firebase-Auth-Id-Token", idToken || "");

  return getSdk(client);
}

type DateTime =
  | Scalars["ISO8601DateTime"]["input"]
  | Scalars["ISO8601DateTime"]["output"];

// UTC でのISO8601DateTime の文字列が UTC を日本時刻にし、yyyy/MM/dd HH:mm:ss にフォーマットする
export const formatDateTime = (datetime: DateTime): string => {
  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = ("00" + (date.getMonth() + 1)).slice(-2);
  const day = ("00" + date.getDate()).slice(-2);
  const hour = ("00" + date.getHours()).slice(-2);
  const minute = ("00" + date.getMinutes()).slice(-2);
  const second = ("00" + date.getSeconds()).slice(-2);
  return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
};

// UTC でのISO8601DateTime の文字列が UTC を
// 日本時刻で計算し直し、yyyy/MM/dd に変換する
export const formatDate = (datetime: DateTime): string => {
  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = ("00" + (date.getMonth() + 1)).slice(-2);
  const day = ("00" + date.getDate()).slice(-2);
  return `${year}/${month}/${day}`;
};

export const EMPTY_DATE = "1970/01/01";

// `AnswerUnion['__typename']` を `AnswerTypeEnum` に変換する
// AnswerUnion は __typename を用いて、 Discriminated Union になっているが、
// 一部関数で AnswerTypeEnum に変換したい場合があるので、そのための関数
// サーバサイドで __typename のかわりに AnswerTypeEnum を返すようになったら、
// この関数は不要になる。
export const convertAnswerUnionTypenameToAnswerTypeEnum = (
  answerTypename: AnswerUnion["__typename"]
): AnswerTypeEnum | undefined => {
  switch (answerTypename) {
    case "TextAnswer":
      return AnswerTypeEnum.Text;
    case "ShortTextAnswer":
      return AnswerTypeEnum.ShortText;
    case "LongTextAnswer":
      return AnswerTypeEnum.LongText;
    case "UrlAnswer":
      return AnswerTypeEnum.Url;
    case "ImageUrlAnswer":
      return AnswerTypeEnum.ImageUrl;
    case "DateAnswer":
      return AnswerTypeEnum.Date;
    case "ReferenceAnswer":
      return AnswerTypeEnum.Reference;
    case "NumberAnswer":
      return AnswerTypeEnum.Number;
    case "LocationAnswer":
      return AnswerTypeEnum.Location;
    case undefined:
      return undefined;
    default:
      throw new ExhaustiveError(answerTypename);
  }
};

export function isAnswerTypeEnum(value: string): value is AnswerTypeEnum {
  return Object.values(AnswerTypeEnum).includes(value as AnswerTypeEnum);
}
