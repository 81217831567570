/**
 * fac = Fussy Analytics Cookie
 */
import crypto from "crypto";
import { parseCookies, setCookie } from "nookies";

const maxAge = 20 * 365 * 24 * 60 * 60;

const facVersion = "00";

export function generateFac(rand: Buffer = crypto.randomBytes(32)): string {
  const randomStr = crypto.createHash("sha1").update(rand).digest("hex");
  return facVersion + randomStr;
}

export function getFac(): string | null {
  return parseCookies()["fac"] as string | null;
}

export function setFac(): string {
  const fac = parseCookies()["fac"];

  if (fac) {
    return fac;
  }

  const generated = generateFac();
  setCookie(null, "fac", generated, { path: "/", maxAge, secure: true });

  return generated;
}
