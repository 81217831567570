import { Environment } from "~/lib/environment";

export function loadDevelopmentEnvironment(): Environment {
  let backendHost = process.env.BACKEND_HOST; // backend ではこちらが読まれるはず
  let microCmsEndpoint = process.env.MICRO_CMS_ENDPOINT;
  let microCmsApiKey = process.env.MICRO_CMS_API_KEY;

  // frontend ではこちらが読まれるはず
  if (!backendHost) {
    backendHost = process.env.NEXT_PUBLIC_BACKEND_HOST;
  }

  if (!backendHost) {
    throw Error("in development, must set BACKEND_HOST");
  }

  return {
    backendHost,
    microCmsEndpoint,
    microCmsApiKey,
  };
}
