import { SerializedStyles, css } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { IBM_Plex_Sans_JP } from "next/font/google";
import { Heebo } from "next/font/google";

import { SP_MODE_WIDTH } from "~/lib/client/hooks/useIsSpMode";

// 共通で使う色と、機能・体験ごとに色を分ける（分けたい）
const Color = {
  primary: "#E03500",
  background: "#202020",
  character: {
    menuFont: "#989898",
    sentence: "#1f1f1f",
    subSentence: "#989898",
  },
  errorMessage: "#ff0000",
  infoMessage: "#bdbdbd",
};

export const Tp7Color = {
  primary: "#FF480F",
  secondary: "#4AE800",
  text: "#000",
};

export const Size = {
  contentsImage: 60,
};

export const defaultUserIcon =
  "https://d1stpor4o81wuq.cloudfront.net/images/FlzQW8VacAInv98.jpg";

export const tp7FussyTheme = createTheme({
  palette: {
    primary: {
      main: Tp7Color.primary,
      contrastText: "#fff",
    },
    secondary: {
      main: Tp7Color.secondary,
      contrastText: "#fff",
    },
    error: {
      main: Color.errorMessage,
    },
    info: {
      main: Color.infoMessage,
    },
    text: {
      primary: "#000",
      secondary: "#777",
    },
    background: {
      default: "#fff",
      paper: "#eee",
    },
    divider: "#ccc",
  },
});

const font = IBM_Plex_Sans_JP({ weight: ["400", "600"], preload: false });
const engFont = Heebo({ weight: ["100", "400", "700"], subsets: ["latin"] });

export { font, engFont };

const fixedWidth = (width: number | string) => css`
  width: ${width};
  min-width: ${width};
  max-width: ${width};
`;

const fixedHeight = (height: number | string) => css`
  height: ${height};
  min-height: ${height};
  max-height: ${height};
`;

const flexRow = css`
  display: flex;
  flex-flow: row;
`;

const flexColumn = css`
  display: flex;
  flex-flow: column;
`;

const wrapLongTextStyle = css`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const mediaQueryIsMobile = (style: SerializedStyles) => css`
  @media (max-width: ${SP_MODE_WIDTH}px) {
    ${style}
  }
`;

const mediaQueryIsDesktop = (style: SerializedStyles) => css`
  @media (min-width: ${SP_MODE_WIDTH + 1}px) {
    ${style}
  }
`;

export {
  fixedWidth,
  fixedHeight,
  flexRow,
  flexColumn,
  mediaQueryIsMobile,
  mediaQueryIsDesktop,
  wrapLongTextStyle,
};

export default Color;
