import { useEffect, useState } from "react";

type WindowSize = { width: number; height: number };

const useWindowSize = (): WindowSize | undefined => {
  const [size, setSize] = useState<WindowSize | undefined>();

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener("resize", () => {
        setSize({ width: window.outerWidth, height: window.outerHeight });
      });
      setSize({ width: window.outerWidth, height: window.outerHeight });

      return () =>
        window.removeEventListener("resize", () => {
          setSize(undefined);
        });
    }
  }, []);
  return size;
};

// 開発しやすさのため、開発モードではしきい値を小さくする
export const SP_MODE_WIDTH =
  process.env.NODE_ENV === "development" ||
  process.env.NEXT_PUBLIC_ENV === "development"
    ? 768
    : 1200;

/**
 * FIXME: ApplicationContext に移動したい author: funwarioisii
 * @returns undefined: 初期値, true: SPモード, false: PCモード
 */
const useIsSpMode = () => {
  const [isSpMode, setIsSpMode] = useState<boolean>();

  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize === undefined) {
      setIsSpMode(undefined);
      return;
    }

    setIsSpMode(windowSize.width < SP_MODE_WIDTH);
  }, [windowSize]);

  return isSpMode;
};

export default useIsSpMode;
