import { User, onAuthStateChanged } from "@firebase/auth";
import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { tp7FussyTheme } from "~/lib/client/design";
import { setFac } from "~/lib/client/fac";
import { getFirebaseAuth } from "~/lib/client/firebase";
import { createGraphqlClient } from "~/lib/graphql/graphqlClient";
import "~/styles/globals.css";

const queryClient = new QueryClient();

export type CurrentUser = {
  id: number;
  name: string;
  iconUrl: string | null | undefined;
  bio?: string | null;
};

const AppContext = createContext(
  {} as {
    currentUser: CurrentUser | null | undefined;
    setCurrentUser: Dispatch<SetStateAction<CurrentUser | null | undefined>>;
  }
);

const loadingStatuses = ["loading", "notLoggedIn", "loggedIn"] as const;

export const currentUserLoadingStatus = (
  currentUser: CurrentUser | null | undefined
): (typeof loadingStatuses)[number] => {
  if (currentUser === undefined) {
    return "loading";
  }
  if (currentUser === null) {
    return "notLoggedIn";
  }
  return "loggedIn";
};

export const useAppContext = () => useContext(AppContext);

function FussyApp({ Component, pageProps }: AppProps) {
  const [currentUser, setCurrentUser] = useState<
    CurrentUser | null | undefined
  >(undefined);
  const router = useRouter();

  useEffect(() => {
    const authStateChanged = onAuthStateChanged(
      getFirebaseAuth(),
      async (firebaseUser: User | null) => {
        if (!firebaseUser) {
          setCurrentUser(null);
          return;
        }

        const idToken = await firebaseUser.getIdToken();
        const graphqlClient = createGraphqlClient(idToken);

        // sign up が完了したとき、Firebase User は作成されるが、
        // Fussy User は作成されていない。
        // そのため、Fussy User が存在しない場合は、
        // Catch して、setCurrentUser(null) する。
        try {
          const { me } = await graphqlClient.me();
          if (!me) {
            setCurrentUser(null);
            return;
          }
          setCurrentUser({
            id: me.id,
            name: me.name,
            bio: me.bio,
            iconUrl: me.avatarImageUrl,
          });
        } catch (e) {
          setCurrentUser(null);
          return;
        }
      }
    );

    return () => {
      authStateChanged();
    };
  }, [router]);

  useEffect(() => {
    setFac();
  }, []);

  return (
    <SessionProvider session={pageProps.session}>
      <AppContext.Provider value={{ currentUser, setCurrentUser }}>
        <ThemeProvider theme={tp7FussyTheme}>
          <QueryClientProvider client={queryClient}>
            <Component {...pageProps} />
            <Analytics />
            <SpeedInsights />
          </QueryClientProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </SessionProvider>
  );
}

export default FussyApp;
